<template>
  <div id="record-label">
    <Navbar class="white-logo" />

    <div class="background">
      <img src="../assets/background-01.svg" alt="background image" />
    </div>

    <section>
      <h1 class="page-title">Record Labels</h1>
      <p>
        If you are a U.S. based record label, we have a range of services<br />to
        help you with your mechanical licensing needs
      </p>
      <a href="" class="button primary shadow">SIGN UP FOR HFA</a>
      <img src="../assets/record-labels-graphs.svg" alt="background image" />
    </section>

    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "RecordLabel",
  components: {
    Navbar,
    Footer
  },
  metaInfo: function() {
    return {
      title: " - Record Labels",
      meta: [
        {
          name: "description",
          content:
            "If you are a U.S. based record label, we have a range of services to help you with your mechanical licensing needs"
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  }
};
</script>

<style lang="scss">
#record-label {
  > .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    overflow: hidden;
    z-index: -1;

    img {
      width: 119vw;
      position: relative;
      top: -10vh;
      left: -43.5vw;
      height: 74vw;
    }
  }

  section {
    height: 110vh;

    h1 {
      color: #fff;
      font-size: 2em;
      letter-spacing: 0.2px;
      margin-top: 60px;
      margin-bottom: 50px;
    }
    p {
      color: #fff;
      margin: 0 0 0;
      font-size: 1.1875em;
      line-height: 1.68;
    }
    .button {
      font-size: 1.375em;
      margin-top: 7.687vw;
      padding: 0.8em 0;
      width: 260px;
      display: block;
    }
    img {
      position: absolute;
      top: 26.9531vh;
      right: 3.07467vw;
      width: 51.538vw;
    }
  }
}
</style>
